<template>
    <div class="input-component">
        <div class="d-flex justify-content-between align-items-baseline">
            <label class="input-component-label" :style="propStyle">{{label}}: {{getLabelValue}}</label>
        </div>
        <v-slider :value="value"
                  @input="handleInput"
                  :color="color"
                  :thums-size="16"
                  :track-color="color"
                  :thumb-color="color"
                  :disabled="disabled"
                  thumb-label
                  step="1"
                  :min="min" :max="max"></v-slider>
    </div>
</template>

<script>
    export default {
        name: "Slider",

        data() {
            return ({})
        },

        props: [ "label", "color", "value", "min", "max", "disabled", "unit" ],

        methods: {
            handleInput: function (e) {
                this.$emit('input', e);
            }
        },

        computed: {
            propStyle: function () {
                return {
                    color: this.disabled ? "rgba(0,0,0,.38)" : "inherit",
                }
            },

            getLabelValue: function () {
                return `a cada ${this.value} ${this.unit}${this.value > 1 ? 's' : ''}`;
            }
        },
    };
</script>

<style>
    /*.input-component-label {
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 0.4rem;
        letter-spacing: -0.02rem;
    }

    .forgot-password-label {
        font-size: 1rem;
        font-weight: 500;
        text-decoration: underline;
        color: #2F80ED;
    }

    .input-component .v-text-field--outlined {
        border-radius: 0.35rem;
    }

    .input-component .v-text-field--outlined fieldset {
        background: #F8F8F8;
        border: 0.063rem solid #BBB;
    }

    .v-input__append-inner {
        margin-top: 12px !important;
    }*/
</style>