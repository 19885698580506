<template>
    <div class="d-flex flex-column mt-8 h-100">
        <div class="d-flex w-100 justify-content-between flex-column-reverse flex-md-row">
            <h2 class="mb-8">Informações do seu perfil</h2>
            <v-btn color="black"
                   elevation="0"
                   id="btn-sair"
                   class="mr-8 py-5 px-8 mb-5 mb-md-0"
                   :width="$widerThan('sm', $vuetify.breakpoint.name) ? '250' : '100%'"
                   outlined
                   @click="logout">Sair da minha conta</v-btn>
        </div>
        <section class="container-form d-flex flex-column flex-md-row justify-content-between align-items-start w-100 h-75">
            <div id="container-form-edit" class="d-flex flex-column justify-content-between">
                <v-form ref="form"
                        class="standard-form w-100"
                        v-model="valid"
                        @submit.prevent="validateForm">
                    <InputComponent label="Nome completo"
                                    color="profile"
                                    :rules="[rules.requiredRule, rules.nameRule]"
                                    v-model="formModel.name"
                                    hide-details/>

                    <hr />

                    <v-checkbox v-model="enableFrequency"
                                color="profile"
                                label="Desejo receber e-mails com notícias dos temas selecionados"></v-checkbox>

                    <SliderComponent label="Frequência de envio de e-mail"
                                     color="profile"
                                     v-model="formModel.frequency"
                                     min="1" max="31"
                                     :disabled="!enableFrequency" 
                                     unit="dia"
                                     v-if="enableFrequency"></SliderComponent>

                    <v-btn :style="styleButton"
                           elevation="0"
                           :class="saved ? 'btn w-100 mt-5 prevent-click' : 'btn w-100 mt-5'"
                           :loading="saving"
                           :outlined="saved"
                           :color="saved ? 'black' : 'profile'"
                           type="submit">
                        <div v-if="!saved">Alterar dados</div>
                        <div v-else>
                            <v-icon>mdi-check</v-icon>
                            Dados salvos!
                        </div>
                    </v-btn>
                </v-form>
            </div>

            <div class="divider divider-perfil" />

            <div class="d-flex h-100 w-100 flex-column align-self-start justify-content-between pb-5">
                <div class="w-100" v-if="fetchedFavorites">
                    <p class="ma-0" id="label-favorites-categories">
                        <strong>Suas categorias preferidas</strong>    
                    </p>
                    <v-chip-group active-class="chip-selected"
                                class="category-group"
                                multiple column>
                        <v-chip v-for="category in selecteds"
                                class="category-v-chip"
                                active-class="chip-selected"
                                :key="category.id"
                                label 
                                color="profile"
                                text-color="white">
                            {{category.title}}
                        </v-chip>
                    </v-chip-group>
                </div>

                <v-btn :style="styleButton"
                        elevation="0"
                        class="btn mt-5 align-self-center"
                        :width="$widerThan('sm', $vuetify.breakpoint.name) ? '' : '100%'"
                        color="profile"
                        to="/categorias">
                        <v-icon class="mr-3" v-if="$widerThan('xs', $vuetify.breakpoint.name)">
                            mdi-shape
                        </v-icon>
                        Editar categorias preferidas
                    </v-btn>
            </div>
        </section>
    </div>
</template>

<script>
    import rules from '../utils/rules';

    import axios from 'axios';

    import InputComponent from '../components/Input.vue';
    import SliderComponent from '../components/Slider.vue';

    export default {
        name: 'Perfil',

        components: {
            InputComponent,
            SliderComponent,
        },

        data() {
            return ({
                formModel: {
                    name: '',
                    frequency: 7
                },
                enableFrequency: false,
                user: {},
                rules,
                fetchedFavorites: false,
                selecteds: [],
                saving: false,
                saved: false,
                valid: false,
                styleButton: {
                    color: this.$colors.grays.white,
                },
            })
        },

        methods: {
            validateForm: function () {
                if (this.$refs.form.validate()) {
                    this.saving = true;
                    setTimeout(() => {
                        this.handleSubmit();
                    }, 1000);
                }
            },

            handleSubmit: function () {
                console.log(this.formModel)
                const bodyPut = {
                    ...this.formModel,
                    frequency: this.enableFrequency ? this.formModel.frequency : -1,
                }
                axios.put("/api/Home/UpdateUserConfigs", bodyPut)
                    .then(resp => {
                        if (resp.data.success) {
                            this.$store.dispatch("updateUser", bodyPut);
                        }

                        this.fetchedFavorites = true;
                        this.saving = false;
                        this.saved = true;
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },

            logout: function () {
                this.$store.dispatch('logout')
                    .then(() => {
                        this.$router.push('/')
                    })
            },

            fetchUserFavorites: function () {
                this.user = this.$store.getters.authUser;
                this.formModel = { ...this.user };
                this.enableFrequency = this.user.frequency > 0
                axios.get(`/api/Home/GetUsersPreferencesFromAPI?id=${this.user.id}`)
                    .then(resp => {
                        this.fetchedFavorites = true;
                        this.selecteds = resp.data;
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
        },

        computed: {
            checkPswd: function () {
                return this.formModel.newPswd === this.formModel.confirmNewPswd || "Senhas não conferem"
            },
        },

        created() {
            this.fetchUserFavorites();
        }
    }
</script>

<style>
    #label-favorites-categories {
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 0.4rem;
        letter-spacing: -0.02rem;
    }

    #container-form-edit {
        width: 50%;
    }

    .prevent-click {
        pointer-events: none
    }

    .divider-perfil {
        background: linear-gradient(
            180deg,
            rgba(155, 81, 224, 0) 0%,
            #9B51E0 36.98%,
            #9B51E0 66.67%,
            rgba(155, 81, 224, 0) 100%
        );
    }

    .category-group .v-chip {
        margin-bottom: 8px !important;
        margin-right: 12px !important;
        padding: 9px 15px;
        font-size: 1.125rem;
        font-weight: 700;
        border-radius: 10px !important;
        border: 2px solid #2F80ED;
        height: 40px;
    }

    .category-group .v-chip:before {
        border-radius: 0;
    }

    @media (max-width: 960px) {

        .divider-perfil {
            background: linear-gradient(
                90deg,
                rgba(155, 81, 224, 0) 0%,
                #9B51E0 36.98%,
                #9B51E0 66.67%,
                rgba(155, 81, 224, 0) 100%
            );
        }

        #container-form-edit {
            width: 100%;
        }
    }
</style>